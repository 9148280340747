<template>
	<div>
		<div class="content">
			<div class="mb20 pageTitle"><b>提现</b></div>
			<div>
				<el-form class="form" label-width="120px" v-loading="loading">
					<el-form-item label="可提现金额:">
						<div class="flexX flexBetween flexcenter">
							<span>￥{{allAmount}}</span>
							<el-button size="mini" type="warning" @click="setAllAmount">全部提现</el-button>
						</div>

					</el-form-item>
					<el-form-item label="提现金额:">
						<el-input type="text" placeholder="请输入提现金额" v-model="amount" clearable></el-input>
					</el-form-item>
					<el-form-item>
						<el-button size="mini" type="primary" @click="onSubmit">提现</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
	</div>
</template>

<script>
	import local from '@/util/local'
	import {
		isEmpty,
		isPositiveNumber,
		isMoney,
		isMobile,
		isIncludeHanzi,
		isAllNumber
	} from '@/util/validate'
	import {
		Message
	} from 'element-ui'
	export default {
		data() {
			return {
				loading: false,
				userinfo: {},
				amount: 0,
				allAmount: 0,
				info: [
					"不允许私自私下将银行卡中余额转出；当私自从银行卡转出，导致小组账变异常，将会停止整个小组的账号，请特别小心",
					"银行卡的余额变更，只能通过提交提现方式变更",
					"银行卡提现，需要先提交提现申请，而且只能提奖金部分，其他部分通过工作中返回",
					"提现申请后，系统审核通过，会通过代付方式转账，并转入您的充值卡中(即充值的卡)"
				]
			};
		},
		created() {
			this.infoTotal()
		},
		filters: {
			tofixed: function(value) {
				if (value || value == 0) {
					let val = Math.floor(((value * 1) * 100).toPrecision(12))
					return ((val / 100).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
				}
			}
		},
		methods: {
			infoTotal() {
				this.$api.infoTotal({}).then(res => {
					if (res.status == 1) {
						this.userinfo = res.data
						this.amount =this.userinfo.tx_money
						//  parseFloat(100 * Number(this.userinfo.tx_money)).toFixed(0);
						this.allAmount = this.userinfo.tx_money;
						this.$local.set('bpcUserinfo', res.data);
					}
				})
			},
			setAllAmount: function() {
				this.amount = this.allAmount
				// parseFloat(100 * Number(this.allAmount)).toFixed(0)
			},
			onSubmit() {
				var t = this;
				if (this.userinfo.mention_card == 0) {
					this.$message({
						type: 'info',
						message: '请先绑定提现卡'
					})
					this.$confirm('还没有提现卡，现在去绑定提现卡吗?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						this.$router.push({
							path: '/cardWithdraw'
						})
					}).catch(() => {});
				} else {
					if (isEmpty(this.amount, "提现金额不能为空")) {
						return
					}
					if (!isAllNumber(this.amount, "提现金额只能是正整数")) {
						this.$message({
							type: 'error',
							message: '提现金额只能是正整数'
						})
						return
					}
					if (Number(this.amount) <= 0) {
						this.$message({
							type: 'error',
							message: '提现金额必须大于'
						})
						return
					}
					if (Number(this.amount) > parseFloat(100 * Number(this.allAmount)).toFixed(0)) {
						this.$message({
							type: 'error',
							message: '可提现金额不足'
						})
						return
					}
					let data = {
						money: this.amount
					};
					this.$confirm('确认提现吗?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						this.$api.mentionMoney(data).then(res => {
							if (res.status == 1) {
								this.$router.push({
									path: '/reportWithdraw'
								})
								this.$message({
									type: 'success',
									message: res.msg
								});
							}
						})

					}).catch(() => {
						this.$message({
							type: 'info',
							message: '已取消操作'
						});
					});
				}
			}
		},
		watch: {},
	};
</script>

<style lang="scss">
	@import '@/style/variables.scss';

	.content {
		.pageTitle {
			background-color: $navSubColor;
			padding: 10px;
		}

		.tips {
			width: 460px;
		}

		.form {
			width: 460px;
		}
	}
</style>
